.slate-editor {
    ol, menu {
        list-style: auto !important;
        margin: revert !important;
        padding: revert !important;
    }
    
    ul {
        list-style: unset !important;
        margin: revert !important;
        padding: revert !important;
    }
    
    
    blockquote {
        border-left: 2px solid #ddd;
        margin-left: 0;
        margin-right: 0;
        padding-left: 10px;
        color: #aaa;
        font-style: inherit;
    }
    
    :not(pre) > code {
        font-family: monospace;
        background-color: #eee;
        padding: 3px;
    }

    h1 {
        margin: revert;
        margin: 0.8rem 0 0.8rem 0;
        line-height: 1.9rem;
        code {
            span {
                line-height: 2.6rem;
            }
        }
    }

    h2 {
        font-size: revert;
        font-weight: revert;
        margin: 0.5rem 0 0.5rem 0;
        line-height: 1.4rem;

        code {
            span {
                line-height: 2rem;
            }
        }
    }
}