@tailwind base;
@tailwind components;
@tailwind utilities;

/* blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
    margin: 0;
}

h1, h2, h3, h4, h5, h6 {
    font-size: revert;
    font-weight: revert;
}

ol, ul, menu {
    list-style: revert;
    margin: 0;
    padding: revert;
} */

dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre{
    margin: revert !important;
}

ol, menu {
    list-style: auto !important;
    margin: 0 !important;
    padding: revert !important;
}

ul {
    list-style: unset !important;
    margin: 0 !important;
    padding: revert !important;
}


blockquote {
    border-left: 2px solid #ddd;
    margin-left: 0;
    margin-right: 0;
    padding-left: 10px;
    color: #aaa;
    font-style: inherit;
}

:not(pre) > code {
    font-family: monospace;
    background-color: #eee;
    padding: 3px;
}

h1 {
    font-size: revert !important;
    font-weight: revert !important;
    margin: revert !important;
    margin: 0.8rem 0 0.8rem 0 !important;
    line-height: 1.9rem !important;
    code {
        span {
            line-height: 2.6rem !important;
        }
    }
}

h2 {
    font-size: revert !important;
    font-weight: revert !important;
    margin: 0.5rem 0 0.5rem 0 !important;
    line-height: 1.4rem !important;

    code {
        span {
            line-height: 2rem;
        }
    }
}

h3 {
    font-size: revert !important;
    font-weight: revert !important;
}