$breakpoint-tablet: 768px;
$breakpoint-large-screen: 1650px;
$breakpoint-medium-screen: 1440px;
#candidate-profile-view {
  div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    vertical-align: baseline;
  }

  .h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
    margin-bottom: 0.5rem;
    font-weight: 500;
    line-height: 1.2;
  }

  img {
    max-width: 100%;
    border: 0;
  }

  button, a, img {
    outline: none;
  }

  ul, li {
    list-style-type: none;
  }

  *, *:before, *:after {
    box-sizing: border-box;
  }

  .mb-0, .my-0 {
    margin-bottom: 0 !important;
  }

  .pb-0 {
    padding-bottom: 0 !important;
  }

  p {
    font-size: 16px;
    line-height: 1.5;
    margin-bottom: 20px;
  }

  a {
    color: #ff5f01;
    text-decoration: none;
    transition: .5s all;
  }

  .left-profile {
    text-align: center;
    -webkit-box-shadow: 28px 0px 18px -1px rgba(0,0,0,.12);
    -moz-box-shadow: 28px 0px 18px -1px rgba(0,0,0,.12);
    box-shadow: 28px 0px 18px -1px rgba(0,0,0,.12);
    height: 100%;
  }

  .section-padding {
    padding: 100px 0;
    border-bottom: 1px solid #eaeaea;
    margin: 0 100px;
    @media (max-width: 991px) {
      margin: 0 30px;
      margin-left: 40px;
    }

    @media (max-width: $breakpoint-tablet) {
      padding: 50px 0;
      margin: 0 30px;
      margin-left: 40px;
    }
  }

  .profile-titles {
    font-size: 26px;
    font-weight: 500;
    margin-bottom: 30px;
    text-transform: uppercase;
  }

  .section-margin-right {
    margin: 0 150px;
  }

  .border-0 {
    border: 0 !important;
  }

  .intro-box {

    .talent-img {

      .tal-fig {
        max-width: 250px;
        margin: 0 auto;

        img {
          border-radius: 100%;
        }

        @media (max-width: $breakpoint-medium-screen) {
          max-width: 170px;
        }
        @media (max-width: $breakpoint-large-screen) {
          max-width: 200px;
        }
      }
    }

    .talent-details {
      margin-top: 25px;

      .talent-name {
        font-size: 34px;
        font-weight: 700;
      }

      .designation {
        color: #c4c4c4;
        font-size: 20px;
        margin-top: 5px;
        line-height: 1.5;
        margin-bottom: 20px;
      }
    }

    .talent-exp {
      margin: 40px 0px;

      p {
        background: #ff5f01;
        color: white;
        //color: #283665;
        width: fit-content;
        margin: 0 auto;
        padding: 14px 16px;
        font-size: 24px;
        font-weight: 600;
        border-radius: 10px;
        margin-bottom: 0 !important;
      }
    }
  }

  .interpersonal-skills {
    .interpersonal-item {
      p {
        background: #ff5f01;
        color: white;
        //color: #283665;
        width: fit-content;
        margin: 0 auto;
        padding: 10px 20px;
        margin-bottom: 15px;
        border-radius: 10px;
        font-weight: 600;
      }
    }
  }

  .achievements-list {
    text-align: left;

    .achievement-title {
      font-weight: 600;
      margin: 15px 0;
      font-size: 18px;
    }

  }

  .talent-interests {

    .interests-item {

      p {
        margin: 15px 0;
        font-weight: 600;
      }

    }

  }

  .proficiencies-boxes {
    display: flex;
    flex-wrap: wrap;
    gap: 1%;

    .proficiencies-item {
      flex: 0 0 49%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border: 1px solid #dbdbdb;
      box-shadow: 0 0 15px rgb(0 0 0/5%);
      margin-top: 15px;
      padding: 10px;
      padding-right: 30px;
      border-radius: 15px;
      position: relative;

      figure {
        flex: 0 0 65px;
        text-align: center;
        max-height: 45px;
        max-width: 35px;
        @media (max-width: 575px) {
            max-height: 35px;
            max-width: 25px;
        }
      }

      .tech {
        font-size: 18px;
        padding-left: 30px;
      }

      .years {
        text-align: right;
        flex-grow: 1;
        flex-basis: 0;
      }

      .prof-line {
        height: 50%;
        position: absolute;
        top: 50%;
        left: calc(100% - 110px);
        transform: translateY(-50%);
        width: 1px;
        border-left: 2px solid #d8d8d8;
      }

    }
  }

  .push-boundaries {
    background: #000;
    padding: 40px 150px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #fff;

    h2 {
      font-size: 20px;
      font-weight: 500;
    }

    a.cta-button {
      color: #000;

      &:hover {
        background: #fff;
        color: #000;
      }

    }

    .cta-button, input[type=submit] {
      font-weight: 700;
      color: white;
      text-transform: uppercase;
      background: #ff5f01;
      padding: 0 30px;
      font-size: 18px;
      display: inline-block;
      text-align: center;
      min-height: 50px;
      line-height: 50px;
      border-radius: 50px;
      position: relative;
      overflow: hidden;
      z-index: 2;
    }

  }

  .list-comm {

    .exp-title {
      margin-bottom: 0;
      font-size: 20px;
      font-weight: 600;
    }

    .exp-sub {
      font-size: 14px;
      margin-bottom: 25px;
    }

    .exp-desc {
      margin-bottom: 30px;

      li {
        position: relative;
        margin-bottom: 22px;
        color: #666;
        font-size: 14px;
        line-height: 1.3;

        &:before {
          position: absolute;
          content: '';
          top: 3px;
          left: -19px;
          height: 9px;
          width: 9px;
          background-image: url(//www.uplers.com/wp-content/themes/uplers/assets/css/../images/profiles/pointer.svg);
        }
      }
    }

  }

  .exp-box {

    .exp-desc {
      padding-left: 30px;
      position: relative;

      &:before {
        position: absolute;
        content: '';
        height: 95%;
        left: 0;
        border-left: 1px solid #ff5f01;
      }
    }

  }

  .cl-testimonial {
    padding: 40px 150px;
    background: #ff5f01;
    color: white;

    .testimonial-desc {
      p {
        font-size: 20px;
        font-weight: 600;
        margin: 20px 0;
      }
    }
  }

  .major-box {
    .exp-desc {
      padding-left: 20px;
    }
  }

  .education-box {
    display: flex;
    background: #f4f4f4;
    margin-bottom: 20px;
    padding: 20px 30px;
    border-radius: 15px;

    .education-detials {
      padding-left: 20px;

      p {
        margin-bottom: 5px;
      }

      .ed-class {
        font-weight: 700;
        font-size: 18px;
      }

    }


  }

  .tools-grid {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-column-gap: 15px;
    grid-row-gap: 15px;
    text-align: center;
    align-items: center;

    .tools-box {
      border: 1px solid #dbdbdb;
      box-shadow: 0 0 15px rgb(0 0 0/5%);
      height: 100%;
      border-radius: 15px;
      padding: 15px 10px;
      display: flex;
      flex-direction: column;
      justify-content: center;

      figure {
        flex: 1 1 50%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

  }

  @media(max-width: 1650px) {
    .share-profiles {
      padding:80px 0
    }

    .section-padding {
      padding: 80px 0
    }

    .profile-titles {
      font-size: 22px;
      margin-bottom: 24px
    }

    .section-margin-right {
      margin: 0 100px
    }

    .cl-testimonial {
      padding: 40px 100px
    }

    .push-boundaries {
      padding: 40px 100px
    }

    .intro-box {
      .talent-img {
        .tal-fig {
          max-width: 200px
        }
      }
    }

    .intro-box {
      .talent-details {
        .talent-name {
          font-size: 28px
        }
      }
    }

    .intro-box {
      .talent-details {
        .designation {
          font-size: 18px
        }
      }
    }

    .intro-box {
      .talent-exp {
        p {
          padding: 14px 15px;
          font-size: 20px
        }
      }
    }

    .talent-batch {
      figure {
        max-width: 180px;
        margin: 0 auto
      }
    }

    .push-boundaries {
      h2 {
        font-size: 18px
      }
    }

    .list-comm {
      .exp-title {
        font-size: 18px
      }
    }

    .tools-grid {
      .tools-box {
        p {
          font-size: 14px
        }
      }
    }

    .proficiencies-item {
      .tech {
        font-size: 15px
      }
    }

    .proficiencies-item {
      .years {
        font-size: 15px
      }
    }
  }

  @media(max-width: 1440px) {
    .share-profiles {
      padding:60px 0
    }

    .section-padding {
      padding: 60px 0
    }

    .section-margin-right {
      margin: 0 80px
    }

    .cl-testimonial {
      padding: 40px 80px
    }

    .push-boundaries {
      padding: 40px 80px
    }

    .intro-box {
      .talent-exp {
        p {
          padding: 10px 15px
        }
      }
    }

    .intro-box {
      .talent-img {
        .tal-fig {
          max-width: 170px
        }
      }
    }

    .intro-box {
      .talent-details {
        .talent-name {
          font-size: 25px
        }
      }
    }

    .intro-box {
      .talent-details {
        .designation {
          font-size: 16px
        }
      }
    }

    .intro-box {
      .talent-exp {
        p {
          font-size: 18px
        }
      }
    }

    .talent-batch {
      figure {
        max-width: 170px
      }
    }

    .profile-titles {
      font-size: 20px;
      margin-bottom: 20px
    }

    .achievement-desc {
      font-size: 15px
    }

    .talent-achievements {
      .achievement-title {
        margin: 8px 0;
        font-size: 16px
      }
    }

    .proficiencies-item {
      .tech {
        font-size: 14px
      }
    }

    .proficiencies-item {
      .years {
        font-size: 14px
      }
    }

    .list-comm {
      .exp-title {
        font-size: 16px
      }
    }

    .list-comm {
      .exp-sub {
        font-size: 12px;
        margin-bottom: 25px
      }
    }

    .list-comm {
      .exp-desc {
        li {
          margin-bottom: 15px;
          font-size: 12px;
          line-height: 1.3
        }
      }
    }

    .cl-testimonial {
      .testimonial-desc {
        p {
          font-size: 18px
        }
      }
    }

    .education-box {
      .education-detials {
        p {
          font-size: 14px
        }
      }
    }

    .education-box {
      .education-detials {
        .ed-class {
          font-size: 16px
        }
      }
    }

    .proficiencies-boxes {
      gap: 2%
    }
  }

  @media(max-width: 1280px) {
    .push-boundaries {
      h2 {
        font-size: 16px;
        flex: 1;
        padding-right: 10px
      }
    }

    .push-boundaries {
      a.cta-button {
        color: #000;
        font-size: 15px;
        min-height: 40px;
        line-height: 40px;
        padding: 0 24px
      }
    }

    .share-profiles {
      .share-title {
        font-size: 22px
      }
    }

    .share-profiles {
      p {
        font-size: 14px
      }
    }

    .share-profiles {
      p {
        br {
          display: block
        }
      }
    }
  }

  @media(max-width: 1024px) {
    .section-padding {
      margin:0 10%
    }
  }

  @media(max-width: 991px) {
    .intro-box {
      .talent-img {
        .tal-fig {
          max-width: 125px
        }
      }
    }

    .intro-box {
      .talent-details {
        .designation {
          font-size: 14px
        }
      }
    }

    .intro-box {
      .talent-exp {
        p {
          font-size: 14px
        }
      }
    }

    .talent-batch {
      figure {
        max-width: 135px
      }
    }

    .section-padding {
      padding: 40px 0
    }

    .profile-titles {
      font-size: 18px;
      margin-bottom: 15px
    }

    .talent-certificates {
      .certificate-item {
        p {
          margin-bottom: 20px;
          font-size: 14px
        }
      }
    }

    .interpersonal-skills {
      .interpersonal-item {
        p {
          padding: 8px 12px;
          margin-bottom: 10px;
          font-size: 12px
        }
      }
    }

    .tools-grid {
      display: grid;
      grid-template-columns: repeat(3,1fr)
    }

    .nabil-ahmed {
      .tools-grid {
        display: grid;
        grid-template-rows: repeat(3, 1fr)
      }
    }

    .renu-yadav {
      .tools-grid {
        grid-template-rows: repeat(6, 1fr)
      }
    }

    .section-padding {
      margin: 0 30px;
      margin-left: 40px
    }

    .intro-box {
      .talent-details {
        .talent-name {
          font-size: 20px
        }
      }
    }

    .intro-box {
      .talent-exp {
        margin: 15px 0
      }
    }

    .intro-box {
      .talent-details {
        .designation {
          font-size: 12px
        }
      }
    }

    .profile-desc {
      font-size: 14px
    }

    .push-boundaries {
      padding: 40px
    }

    .cl-testimonial {
      padding: 40px
    }

    .cl-testimonial {
      .testimonial-desc {
        p {
          font-size: 16px;
          margin: 15px 0
        }
      }
    }

    .list-comm {
      .exp-sub {
        font-size: 12px;
        margin-bottom: 15px
      }
    }

    .proficiencies-item {
      figure {
        flex: 0 0 35px
      }
    }

    .proficiencies-item {
      .tech {
        font-size: 12px;
        padding-left: 10px
      }
    }

    .proficiencies-item {
      .years {
        font-size: 12px
      }
    }

    .proficiencies-boxes {
      .proficiencies-item {
        padding-right: 15px
      }
    }

    .proficiencies-item {
      .prof-line {
        left: calc(100% - 70px)
      }
    }

    .achievement-desc {
      font-size: 13px
    }

    .talent-interests {
      .interests-item {
        p {
          margin: 10px 0;
          font-size: 14px
        }
      }
    }

    .education-box {
      figure {
        flex: 0 0 15%
      }
    }

    .share-profiles {
      padding: 60px 0
    }

    .share-profiles {
      .share-title {
        font-size: 20px
      }
    }

    .tools-grid {
      grid-column-gap: 20px;
      grid-row-gap: 20px
    }
  }

  @media(max-width: 767px) {

    .section-padding {
      border: none
    }

    .intro-box {
      text-align: center;
      //background: #f2f2f2;
      margin: 0
    }

    .interpersonal-skills {
      text-align: center;
      //background: #f2f2f2;
      margin: 0
    }

    .cl-testimonial {
      .icon {
        figure {
          max-width: 30px
        }
      }
    }

    .talent-certificates {
      padding: 0;
      margin: 0;
      text-align: center
    }

    .profile-titles {
      &.accord-mb {
        background: #f2f2f2;
        padding: 10px 30px;
        display: flex;
        justify-content: space-between
      }
    }

    .profile-titles {
      font-size: 16px;
      font-weight: 600
    }

    .interests-list {
      text-align: center
    }

    .push-boundaries {
      flex-wrap: wrap;
      justify-content: center;
      text-align: center;
      gap: 15px
    }

    .certificate-list {
      margin-top: 20px
    }

    .talent-certificates {
      .certificate-item {
        p {
          br {
            display: block
          }
        }
      }
    }
  }

  @media(max-width: 575px) {
    .proficiencies-boxes {
      .proficiencies-item {
        flex: 0 0 100%
      }
    }

    .proficiencies-item {
      figure {
        max-height: 35px;
        max-width: 25px
      }
    }

    .talent-certificates {
      .profile-titles {
        font-size: 13px;
        margin-bottom: 0
      }
    }


    .tools-grid {
      display: grid;
      grid-template-columns: repeat(2,1fr)
    }

    .tools-grid {
      .tools-box {
        p {
          font-size: 12px
        }
      }
    }

    .renu-yadav {
      .tools-grid {
        grid-template-rows: repeat(9, 1fr)
      }
    }

    .education-box {
      .education-detials {
        padding-left: 5px
      }
    }

    .education-box {
      padding: 20px
    }

    .education-box {
      .education-detials {
        p {
          margin-top: 6px;
          font-size: 12px
        }
      }
    }

    .education-box {
      .education-detials {
        .ed-class {
          font-size: 14px
        }
      }
    }

    .talent-achievements {
      .achievement-title {
        margin: 5px 0;
        font-size: 14px
      }
    }

    .achievement-desc {
      font-size: 11px
    }

    .list-comm {
      .exp-title {
        font-size: 15px
      }
    }

    .list-comm {
      .exp-desc {
        margin-bottom: 15px
      }
    }

    .cl-testimonial {
      .testimonial-desc {
        p {
          font-size: 15px
        }
      }
    }

    .share-profiles {
      .share-title {
        font-size: 18px;
        margin-bottom: 10px
      }
    }

    .share-profiles {
      padding: 40px 0
    }
  }

  @media(max-width: 767px) {
    #more-exp-mb,#more-pro-mb {
      font-size:13px
    }

    #more-exp-mb i,#more-pro-mb i {
      font-size: 10px
    }
  }

}
